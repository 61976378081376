import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './routes/routes'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import FomanticUI from 'vue-fomantic-ui'
import 'fomantic-ui-css/semantic.min.css'
// import SuiVue from 'semantic-ui-vue';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vuelidate from '@vuelidate/core'
import Vue3Geolocation from 'vue3-geolocation';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
//ag-grid imports
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import  { AgGridVue }  from "ag-grid-vue3"; // Vue Grid Logic
import vSelect from 'vue-select'


createApp(App)
	.use(router)
	.use(ElementPlus)
	.use(FomanticUI)
	.use(store)
	.use(Vuelidate)	
	.use(Vue3Geolocation)
	.use(Toast, {
		position: POSITION.TOP_CENTER,
		toastClassName: "toast",
	})
	.use(VueGoogleMaps, {
		load: {
			key: "AIzaSyB6GzShqKWz8Tc1Xq1gUAuHzxkyZO-2Iis",
			// language: 'de',
			libraries: "places"
		},
		autobindAllEvents: true,
	})
	.component('VueDatePicker', VueDatePicker)
	.component("AgGridVue", AgGridVue)	
	.component('v-select', vSelect)
	.mount('#app')
